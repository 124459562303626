<template>
  <tr class="vca-table-nav">
    <td :colspan="colspan" class="vca-table-nav-cell">
      <div class="vca-row">
        <span v-if="!hideSearch"
          ><vca-input
            class="vca-table-search"
            v-model="pg.filter"
            :placeholder="placeholder"
        /></span>
        <div class="vca-right">
          <span>
            {{ $t("table.page.size") }}:
            <div class="vca-border main-color editable vca-page-all">
              {{ pg.listLength }}
              <span style="margin: 0" v-if="maxCount">+</span>
            </div>
          </span>
        </div>
      </div>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "TableHeaderInfiniteScroll",
  props: {
    store: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    colspan: {
      type: Number,
      default: 1,
    },
    hideSearch: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    maxCount() {
      if (this.pg.listLength >= 300 && !this.pg.allLoaded) {
        return true;
      }
      return false;
    },
  },
};
</script>
