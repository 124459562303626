<template>
  <tr class="vca-table-nav bottom-navigation">
    <td :colspan="colspan" class="text-right vca-table-nav-cell">
      <span>
        {{ $t("table.page.size") }}:
        <div class="vca-border main-color editable vca-page-all">
          {{ pg.listLength }}
        </div>
      </span>
    </td>
  </tr>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "TableBottomInfiniteScroll",
  props: {
    store: {
      type: String,
      default: null,
    },
    colspan: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    pg: {
      handler(val) {
        this.$store.commit(this.store + "/pagination", val);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
  },
};
</script>
<style lang="scss">
.bottom-navigation td {
  span {
    display: inline-block;
  }
}
</style>
