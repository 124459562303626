<template>
  <div class="table-container">
    <table class="table-desktop">
      <colgroup>
        <slot name="colspan"></slot>
      </colgroup>
      <thead>
        <tr>
          <td :colspan="colspan">
            <slot name="desktop-filter"></slot>
          </td>
        </tr>
        <table-header-pagination
          v-if="!pg.infinite_scroll"
          :placeholder="getSearchLabel"
          :store="store_pg"
          :colspan="colspan"
        />
        <TableHeaderInfiniteScroll
          v-if="pg.infinite_scroll"
          :placeholder="getSearchLabel"
          :store="store_pg"
          :colspan="colspan"
        />
        <tr>
          <td :colspan="colspan">
            <slot name="desktop-options"></slot>
          </td>
        </tr>

        <tr class="vca-table-header">
          <slot name="desktop-header"></slot>
        </tr>
      </thead>
      <tbody>
        <table-content-prev
          v-if="!pg.isLoading && !pg.infinite_scroll"
          :store="store_pg"
          :colspan="colspan"
        />
        <table-border :colspan="colspan" />

        <slot
          name="desktop-content"
          v-if="!pg.isLoading || pg.infinite_scroll"
        ></slot>
        <tr v-if="pg.isLoading && !pg.infinite_scroll" class="text-center">
          <td :colspan="colspan"><Spinner /></td>
        </tr>

        <tr
          class="vca-table-row last"
          v-if="!pg.isLoading && (!list || list.length == 0)"
        >
          <td :colspan="colspan">
            <p class="text-center">
              {{ $t("table.page.no_results") }}
            </p>
          </td>
        </tr>
        <tr
          v-if="!pg.allLoaded && pg.isLoading && pg.infinite_scroll"
          class="vca-table-row last"
        >
          <td :colspan="colspan"><Spinner /></td>
        </tr>
        <tr
          v-if="pg.allLoaded && pg.infinite_scroll && list.length != 0"
          class="vca-table-row last"
        >
          <td :colspan="colspan">
            <p class="text-center">
              {{ $t("table.page.no_more_results") }}
            </p>
          </td>
        </tr>
        <table-border :colspan="colspan" />
        <table-content-next
          v-if="!pg.isLoading"
          :store="store_pg"
          :colspan="colspan"
        />
        <table-border :colspan="colspan" />
        <table-bottom-pagination
          v-if="!pg.infinite_scroll"
          :store="store_pg"
          :colspan="colspan"
        />
        <TableBottomInfiniteScroll
          v-if="pg.infinite_scroll"
          :store="store_pg"
          :colspan="colspan"
        />
      </tbody>
    </table>

    <table class="table-mobile">
      <colgroup>
        <col width="100%" />
      </colgroup>
      <thead>
        <table-search :store="store_pg" :placeholder="getSearchLabel" />
        <tr>
          <slot name="mobile-filter"></slot>
        </tr>

        <tr class="vca-table-nav">
          <td class="text-right vca-table-sort vca-table-nav-cell">
            <div class="vca-row text-center">
              <slot name="mobile-header"></slot>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <table-content-prev :store="store_pg" v-if="!pg.isLoading" />
        <table-border />

        <slot name="mobile-content" v-if="!pg.isLoading"></slot>

        <tr
          class="vca-table-row last"
          v-if="!pg.isLoading && (!list || list.length == 0)"
        >
          <td>
            <p class="text-center">
              {{ $t("table.page.no_results") }}
            </p>
          </td>
        </tr>

        <table-border />
        <table-content-next v-if="!pg.isLoading" :store="store_pg" />
        <table-border />
        <table-bottom-pagination :store="store_pg" />
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Spinner from "./Spinner.vue";
import TableBottomInfiniteScroll from "./TableBottomInfiniteScroll.vue";
import TableHeaderInfiniteScroll from "./TableHeaderInfiniteScroll.vue";
export default {
  name: "VcaTable",
  components: { Spinner, TableHeaderInfiniteScroll, TableBottomInfiniteScroll },
  props: {
    store: {
      type: String,
      default: null,
    },
    pagination: {
      type: String,
      default: "pg",
    },
    searchLabel: {
      type: String,
      default: null,
    },
    colspan: {
      type: Number,
      default: 1,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    store_pg() {
      return this.store + "/" + this.pagination;
    },
    getSearchLabel() {
      return this.searchLabel ? this.searchLabel : this.$t("table.list.search");
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store_pg}/pagination`];
        }
      },
    }),
  },
};
</script>
